<template>
	<div class="tab-pane p-t-20 cockpitview" v-bind:class="{'active':page=='overview'}" id="create" role="tabpanel" >

		
		<div class = "createnewslettertabe" v-if="canwrite">

			<div v-bind:class="{'hide':page!='stillloading'}"><i class="fa fa-circle-o-notch fa-spin"></i></div>
			<div v-bind:class="{'hide':page=='stillloading'}"></div>   

		</div>       

		<div class ="mb-3 mt-3" v-if="devisetype!='mobil'">

					<button v-if="canwrite" :disabled=" !cockpit.isactive" id ="createnewsletterbtn"
					:class="{'disabled' : !cockpit.isactive}" v-on:click="opennewsletterforcreating()" class = "btn mb-2 ml-auto createnewsletter btn-primary" style="margin-right:15px"> <i style="font-size:18px" aria-hidden="true" class="fa fa-plus-circle mr-2 "></i> Add report</button>
					
					
				</div>

		<div id="draftnewslettertable_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer" >
				
				<div class="row draftnewslettercontent" v-if="canwrite && devisetype != 'mobil'">
					<div class="col-sm-12"> 

						<h3>Draft reports</h3>


				    	<b-table :sort-compare-options="{ numeric: true, sensitivity: 'base' }" id="draftnewslettertable" outlined responsive :items="draftnewsletters" :fields="draftfields" @row-clicked="opennewsletterforediting" :sort-by.sync="draftsortBy"
      :sort-desc.sync="draftsortDesc">
						<template #head()="scope">
							<div class="text-nowrap">
							{{ scope.label }}
							</div>
						</template>
						<template #cell(name)="data">
					        <div class="text-nowrap cellitem" :class="{ 'locked':data.item.lock.userid && data.item.lock.userid != profile._id}">
							{{ data.item.name }} 
							<template v-if="data.item.lock.userid">

								<a @click="overridelock(data.item)" v-if="data.item.lock.userid != profile._id" v-b-tooltip.hover :title="'Currently checked out by ' + data.item.lock.user "><i class="far fa-lock-alt ml-1"></i></a>
							</template>
							
							</div>
					    </template>
						<template #cell(period)="data">
					        <div class="text-nowrap cellitem" :class="{'locked':data.item.lock.userid && data.item.lock.userid != profile._id}">
					        {{ timeFormat(data.item.periodstart) }} - {{ timeFormat(data.item.periodend) }}
							</div>
					    </template>
					    <template #cell(createdby)="data">
					        <div class="text-nowrap cellitem" :class="{'locked':data.item.lock.userid && data.item.lock.userid != profile._id}">
					        	{{ data.item.audit.createdby.firstname }} {{ data.item.audit.createdby.lastname }}
							</div>
					    </template>
					    <template #cell(createdon)="data">
					        <div class="text-nowrap cellitem" :class="{'locked':data.item.lock.userid && data.item.lock.userid != profile._id}">
							{{ dateFormat( data.item.audit.createddate) }}
							</div>
					    </template>
					    <template #cell(lasteditby)="data">
					        <div class="text-nowrap cellitem" :class="{'locked':data.item.lock.userid && data.item.lock.userid != profile._id}">
					        	{{ data.item.audit.modifiedby.firstname }} {{ data.item.audit.modifiedby.lastname }}
							</div>
					    </template>
					    <template #cell(lastediton)="data">
					        <div class="text-nowrap cellitem" :class="{'locked':data.item.lock.userid && data.item.lock.userid != profile._id}">
							{{ dateFormat( data.item.audit.modifieddate) }}
							</div>
					    </template>


				    	</b-table>

						<div id="draftnewslettertable_processing" class="dataTables_processing card" style="display: none;">Processing...</div>
					</div>
				</div>
				<div> 
					<p v-if="devisetype == 'mobil' && publishednewsletters.length == 0"> There are no newsletters available.</p>
					<div class="row ">
						<div class="col-sm-12 publishednewslettercontent">

						<h3>Published reports</h3>						


						<b-table id="publishenewslettertable" outlined responsive :items="publishednewsletters_table" :fields="publishedfields" @row-clicked="opennewsletterforviewing">
							<template #head()="scope">
								<div class="text-nowrap">
								{{ scope.label }}
								</div>
							</template>
							<template #cell()="data">
								<div class="text-nowrap cellitem">
								{{ data.value }}
								</div>
							</template>
						</b-table>

						</div>

					</div>
				</div>
			</div>


		<div v-if ="lockwarning" >
			<b-popover
			:target="popovertarget"
			custom-class= "warning"
			placement="topright"
			ref="popover"
			id = "lockpopover" 
			@show="onpopovershow"
			title="Newsletter is locked"
			:show.sync="lockwarning">


			<p> This draft newsletter is currently locked by {{ activelockedto.lock.user }}. <br>Ask them to close it or try again later.</p>
		</b-popover>
	</div>
	<newslettersmodal
	:viewer="viewmode"
	:token="token" 
	:cockpitid="opencockpitid" 
	:shown="creatingnewsletter" 
	:newsletterid="opennewsletterid" 
	:loggedinuser="loggedinuser" 
	@onmodalhidden="modalnewsletterhidden()">							
</newslettersmodal>  

<b-modal v-model="openlocknewsletter" size="sm">
	<template v-slot:modal-header>
		<h3 class="modal-title"><span>  Override lock? </span></h3>
		<a class = "close-modal dialog-close-button mr-2">
			<i class="fal fa-times fa-lg " @click="openlocknewsletter = false"></i>
		</a>
	</template>

	This draft newsletter has been locked for editing. This may be because it is opened on another device or browser, or because the author got disconnected during an editing session.<br>

	To minimize the risk of accidentally overwriting content, you should ensure that all other instances of this draft newsletter are closed. Do you wish to proceed?

	<template v-slot:modal-footer>
		   <button-gruppe  @no ="openlocknewsletter = false" @yes = "opennewsletterforediting(activenewsletter,cockpit._id,true); openlocknewsletter = false"></button-gruppe>

	</template>

</b-modal>

</div>
</template>

<script type="text/javascript"></script>
<style type="text/css">
	

</style>
<style type="text/css">

	#draftnewslettertable_wrapper .table {
	  border: 1px solid #d5dae3 !important;
	}

	#draftnewslettertable_wrapper .table-responsive tbody tr td, .table-responsive thead tr th {
	  border: 1px solid #d5dae3 !important;
	}

  	#draftnewslettertable_wrapper .table-responsive {
	  scrollbar-width: thin;          /* "auto" or "thin" */
	  scrollbar-color: var(--N200);
	}

	html::-webkit-scrollbar-track
	{
		background-color: #FFFFFF !important;
	}

	html::-webkit-scrollbar
	{
		width: 10px;
		background-color: #F5F5F5 !important;
	}

	html::-webkit-scrollbar-thumb
	{
		background-color: #c9c8c8 !important;
	}
	html::-webkit-scrollbar-thumb:hover
	{
		background-color: #b3b2b2 !important;
	}


.cellitem {
	cursor: pointer !important;
}

.draftnewsletter_title{
	margin: -12px;
	padding: 12px;
}


.locked{
	cursor: default !important;
}
</style>
<script>
	import Cockpitsapi from '@/services/api/cockpits'
	import Newslettersapi from '@/services/api/newsletters'

	import objectivesCard from '@/views/cockpit/components/objectivesCard'
	import newsfeedlist from '@/components/newsfeed/components/newsfeedlist'

	import newslettersmodal from './newslettersmodal'
	
	

	import moment from 'moment';

	import {bus} from '@/main'

	export default{
		name:'newsletterpages',
		data(){
			return{
				showaipanel:true,
				activetable:'draft',
				popovertarget:null,
				drafttable:{},
				publishedtable:{},
				mode:"editor",
				draftsortBy: 'createdon',
        		draftsortDesc: true,
				draftnewsletters : [],
				publishednewsletters : [],
				isDraftingNewsletter:false,
				template:'team1',
				periodStart:null,
				periodend:null,
				newslettertitle:'',

				datefrom : new Date(),
				dateto : new Date(),
				draftstable : null,
				publishedtable : null,
				items: [],          
				errors: [],
				invalidfrom : "empty",
				invalidto : "empty",
				invalidtitle : "empty",
				creatingnewsletter : false,
				newsletter : {},
				currentbodytext : "",
				newsletterviewerdata : "",
				commited : true,
				editor : null,     
				status : "idle",
				viewportheight: 0,
				viewportwidth: 0,
				editing : false,
				haschanges : false,
				maxstep : 1,
				page : 'overview',
				preview : 'desktop',
				commentposting : '',
				activecomment : {},
				publishconfirmation : false,
				editorcontent : "",
				devisetype: '',
				pagemode:'notready',
				activenewsletter:{},
				showdeleteactivenewsletter:false,
				viewmode:false,
				opennewsletterid:"",
				opencockpitid:"",
				lockwarning : false,
				activelockedto : {},
				openlocknewsletter: false

			}
		},

		props:{

			cockpitdata:{
				type:Object,
				default:()=>{}
			},
		
			
		},

		computed:{

			publishednewsletters_table(){

				let table = []

				let self = this

				this.publishednewsletters.forEach( function( item , key ){

					const period = self.timeFormat(item.periodstart) + " - " + self.timeFormat(item.periodend)
					const publishedby = item.published.publishedby.firstname + " " + item.published.publishedby.lastname
					const publishedon = self.dateFormat(item.published.date)
					const viewnum = item.statistics && item.statistics.viewcount ? item.statistics.viewcount : 0
					const usernum = item.statistics && item.statistics.viewcountunique ? item.statistics.viewcountunique : 0

					let row = { "id":item._id, "name":item.name, "period":period, "publishedby":publishedby, "publishedon":publishedon, "views":viewnum, "users":usernum, "published":item.published }

					table.push( row )
				})

				return table

			},

			draftnewsletters_table(){

				let table = []

				let self = this

				this.draftnewsletters.forEach( function( item , key ){
					
					const period = self.timeFormat(item.periodstart) + " - " + self.timeFormat(item.periodend)
					const createdby = item.audit.createdby.firstname + " " + item.audit.createdby.lastname
					const createdon = self.dateFormat(item.audit.createddate)
					const lasteditby = item.audit.modifiedby.firstname + " " + item.audit.modifiedby.lastname
					const lastediton = self.dateFormat(item.audit.modifieddate)

					let row = { "id":item._id, "name":item.name, "period":period, "createdby":createdby, "createdon":createdon, "lasteditby":lasteditby, "lastediton":lastediton, "lock":item.locked?item.locked:{} }

					table.push( row )
				})

				return table

			},

			

			 orgkey(){
		      return this.$route.params.orgname ? this.$route.params.orgname : null
		    },

			canwrite(){

				let toreturn = false

				toreturn = this.cockpit.myrole && ( this.cockpit.myrole.toUpperCase() == 'OWNER' || this.cockpit.myrole.toUpperCase() == 'CONTRIBUTOR') ? true : false

				if( !this.cockpit.isactive ){
					toreturn = false
				}

				return toreturn
			},

			draftfields(){ 
				return [
			          {
			            key: 'name',
			            sortable: false,
			            label: 'Name',
			          },
			          {
			            key: 'period',
			            label: 'Period',
			            sortable: false,
			            class: 'text-center' 
			          },
			          {
			            key: 'createdby',
			            label: 'Created by',
			            sortable: false,
			            class: 'text-center' 
			          },
			          {
			            key: 'createdon',
			            label: 'Created on',
			            sortable: false,
			            class: 'text-center' 
			          },
			          {
			            key: 'lasteditby',
			            label: 'Last edit by',
			            sortable: false,
			            class: 'text-center' 
			          },
			          {
			            key: 'lastediton',
			            label: 'Last edit on',
			            sortable: false,
			            class: 'text-center' 
			          }
		        ]
      		},

      		publishedfields(){ 
				return [
			          {
			            key: 'name',
			            sortable: false,
			            label: 'Name',
			            //stickyColumn: true
			          },
			          {
			            key: 'period',
			            label: 'Period covered',
			            sortable: false,
			            class: 'text-center' 
			          },
			          {
			            key: 'publishedby',
			            label: 'Published by',
			            sortable: false,
			            class: 'text-center' 
			          },
			          {
			            key: 'publishedon',
			            label: 'Published on',
			            sortable: false,
			            class: 'text-center' 
			          },
			          {
			            key: 'views',
			            label: 'Views',
			            sortable: false,
			            class: 'text-center' 
			          },
			          {
			            key: 'users',
			            label: 'Users',
			            sortable: false,
			            class: 'text-center' 
			          }
		        ]
      		},
			loggedinuser(){
				return this.$store.state.user
			},

			cockpitid(){
				return this.$route.params.cid
			},
			token(){
				return this.$store.getters.token
			},
			shown(){
				return this.$route.params.newsletterid ?  true : false 
			},
			cockpit(){
				return this.$store.getters.GetCockpit
			},
			profile(){
				return this.$store.state.user.userInfo
			}
			
		},

		mounted(){
			let self = this
		

			

			$('html').off('click',function() {
				self.lockwarning = false
			});							
			$('html').on('click',function() {
				self.lockwarning = false
			});


          

			self.opennewsletterpages()


			self.getWindowWidth()
			self.$nextTick(function() {
				window.addEventListener("resize", self.getWindowWidth);


				if( self.$route.query.nid && self.$route.query.nid != "" && self.$route.query.page == 'newsletter'){

					var cid = self.$route.params.cid

					Newslettersapi.Getnewsletterbyid( self.$route.query.nid , self.token)
	               .then(function(response){
	                 
	                  if( response.data.newsletter.ispublished ){
	    					self.opennewsletterforviewing(response.data.newsletter._id,response.data.cockpit._id)                      
	                    // } else if(self.$store.state.screenType == 'mobile'){
	                   //     self.$router.push('/device/mobile')
	                   // }else{
	                    	//self.opennewsletterforediting(response.data.newsletter, response.data.cockpit._id, true )
	                   }
	                  
	               })

				}

			})

			bus.$off('opennewsletterpages')
			bus.$off('changenewsletters')
			bus.$off('onopennewsletterfromcockpit')
			bus.$off('updatednewsletterviewscount')

			
			bus.$on('updatednewsletterviewscount',this.updatednewsletterviewscount)

			//bus.$on('opennewsletterpages',this.opennewsletterpages)
			//bus.$on('opennewsletterforediting',this.opennewsletterforediting)
			bus.$on('changenewsletters', function(){ 

			Newslettersapi.Getlist( false, this.cockpitid, this.token )
				.then( function(response){
		  		if( response.data.ok ){           
		  			self.draftnewsletters = response.data.data
		  		}

			  	}).finally(()=>{
			  		//$('#draftnewslettertable').DataTables().draw()
			  	})
			})
			bus.$on('onopennewsletterfromcockpit', this.opennewsletterforviewing)
		},
		
		components:{ newsfeedlist,newslettersmodal },


		methods:{

			overridelock(newsletter){

				this.activenewsletter = newsletter
				this.openlocknewsletter=true

			},

			countthisview(){

				return true
			},

			updatednewsletterviewscount(){

				//thisself.publishnewslettertable.ajax.reload();

				this.modalnewsletterhidden()

			},

			getWindowWidth(){
			      //console.log('hello')
			      if( window.innerWidth < 768 ){
			      	this.devisetype= 'mobil'
			      }else if(window.innerWidth < 1024){
			      	this.devisetype = 'tablet'
			      }else{
			      	this.devisetype = 'desktop'
			      }

		  	},

		  	modalnewsletterhidden(){


		  	 //history.pushState("", document.title, "/dashboard/cockpit/id_" + this.cockpit._id );

		  	 this.creatingnewsletter=false; 
		  	 let self = this

		  	 Newslettersapi.Getlist( false, this.cockpitid, this.token )
		  	 .then( function(response){
		  	 	if( response.data.ok ){           

		  	 		self.draftnewsletters = response.data.data

		  	 	}

		  	 }).finally(()=>{
		  	 	/*self.draftnewslettertable.clear()
		  	 	self.draftnewslettertable.rows.add(self.draftnewsletters)
		  	 	this.$nextTick(function(){
		  	 		self.draftnewslettertable.draw()
		  	 	})*/
		  	 	
		  	 })

		  	 Newslettersapi.Getlist( true,self.cockpitid, self.token )
		  	 .then( function(response){

		  	 	if( response.data.ok ){        
		  	 		self.publishednewsletters = response.data.data
		  	 		/*self.publishednewsletters.sort(function(a, b){
		  	 			if(a.published.date < b.published.date) { return 1; }
		  	 			if(a.published.date > b.published.date) { return -1; }
		  	 			else{ return 0}
		  	 		})*/
		  	 	}

		  	 }).finally( ()=>{
		  	 	/*self.publishnewslettertable.clear()
		  	 	self.publishnewslettertable.rows.add(self.publishednewsletters)
		  	 	this.$nextTick(function(){
		  	 		self.publishnewslettertable.draw()
		  	 	})*/
		  	 })
		  	 this.$nextTick(function(){
		  	 	$('#createnewsletterbtn').blur()
		  	 })

		  	},
		  	onpopovershow(){
		  		let self = this

		  		this.$nextTick(function(){
		  			$('#lockpopover').on('click', function(event){
		  				console.log('test')
		  				event.stopPropagation();
		  			})
		  		})
		  	},  
		  	updatenewsletter(){

		  		let self = this

		  	/*

		  	this.draftnewsletters = []
		  	this.publishednewsletters = []
		  	this.$forceUpdate()
	  		$('#publishenewslettertable').DataTable().destroy()
	  		$('#draftnewslettertable').DataTable().destroy()

	  		*/

	  		//console.log('update')

	  		setTimeout( function(){

	  			self.loadnewsletters()

	  		}, 100)
	  	},



	  	opennewsletterpages(){

		  	this.page = 'overview'
		  	this.draftnewsletters = []
		  	this.publishednewsletters = []
		  	this.mode = "editor"

		  	let self = this
		  	


		  	setTimeout( function(){

		  		self.loadnewsletters(function(){
		  			if(this.$route.query.nid && this.$route.query.page == 'newsletter' && this.$route.query.type== 'draft'){

		  			}
		  		})

		  	}, 100)

		  	/*Cockpitsapi.Cockpits( { "_id":this.cockpitid, "token": this.token } )
		  	.then( function(response){
		  		console.log(response.data.ok)
		  		if( response.data.ok || !response.data.ok ){

		  			self.cockpit = response.data.cockpits

		  			setTimeout( function(){

		  				self.inittabs()
		  				self.loadnewsletters()

		  			}, 1000)


		  		}

		  	})
		  	.catch(error => console.log(error))

		  	.finally( () => {
		  		this.pagemode = 'ready'
		  		console.log()
		  	})*/

		  },
		  dateFormat : function ( date ) {
		  	if (!date) return ''
		  		return moment( date ).format('YYYY-MM-DD')
		  },

		  timeFormat:function( unixtime ){

		  	var date = new Date( unixtime * 1000 )

		  	var str = ""

		  	if( date != "" ){
		  		str = moment( date ).format('YYYY-MM-DD')
		  	}

		  	return str 
		  },


		  loadnewsletters(published,callback){

		  	let self = this
		  	

		  	Newslettersapi.Getlist( false, this.cockpitid, this.token )
		  	.then( function(response){
		  			//console.log(response.data)
		  			if( response.data.ok ){   

		  				self.draftnewsletters = response.data.data


		  			}

		  		}).finally( ()=>{

		  			/*self.$nextTick(function(){

		  				if( self.draftnewslettertable == null ){
		  				//console.log(self.draftnewsletters)
		  				self.draftnewslettertable = $('#draftnewslettertable').on( 'init.dt', function () {
		  					$('[data-toggle="tooltip"]').tooltip({delay: {show:500, hide:0}})
						    // console.log( 'Redraw occurred at: '+new Date().getTime() );
						} ).DataTable({
							"data": self.draftnewsletters,
							"searching": false, 
							"bInfo": false, 
							"paging": false,
							"bLengthChange":false,
							"bAutoWidth": false,
							"responsive": true,
							"processing": true,
							"serverSide": false,
							"language": {
								"emptyTable": " There are no draft report available."
							}, 
							"rowId": function(data){return'draftnewsletter_'+data._id},
							"columns": [
							{ "title":"Title", "data": function(data){

								//alert( data.lock.userid )

								if( !data.lock.userid ){
									return '<span>' + data.name + '</span>'
								}else{
									var html = '<div class = "draftnewsletter_title" id = "draftnewsletter_title_'+data._id +'" >'
									html += '<span data-toggle="tooltip" data-offset =" 5px " data-placement="right" title="Currently checked out by '+ data.lock.userdetail.firstname + ' ' + data.lock.userdetail.lastname + '"><span > ' + data.name + ' </span> <span class ="menu-icon"> <i class="far fa-lock-alt ml-1"></i> </span> </span>'
									html += '</div>'
									return html
								}

							}, "orderable": false},
							{ "title":"Period", "data": function( data ){ 
								return self.timeFormat(data.periodstart) + " - " + self.timeFormat(data.periodend)
							}, "orderable": false, className: "text-center" },
							{ "title":"Created by", "data": function(data){

								return data.audit.createdby.firstname + " " + data.audit.createdby.lastname

							}, "orderable": false, className: "text-center" },
							{ "title":"Create on", "data": function(data){
								return self.dateFormat(data.audit.createddate)
                              // return 'test'
                          }, "orderable": true, className: "text-center" },
                          { "title":"Last edit by", "data": function(data){
                          	return data.audit.modifiedby.firstname + " " + data.audit.modifiedby.lastname
                             // return 'test'
                         }, "orderable": false, className: "text-center" },
                         { "title":"Last edit on", "data": function(data){
                         	return self.dateFormat(data.audit.modifieddate)
                            // return 'test'
                        }, "orderable": true, className: "text-center" }
                        ], "order": [[ 3, "desc" ]],   
                    });

					}


				})*/

		  			setTimeout(function(){
		  				if( callback ){
		  					
		  					callback()
		  				}              	

		  			},200)
		  		})

		  		Newslettersapi.Getlist( true,self.cockpitid, self.token )
		  		.then( function(response){

		  			if( response.data.ok ){        
		  				self.publishednewsletters = response.data.data
		  				self.publishedtable = self.publishednewsletters 
		  				/*self.publishednewsletters.sort(function(a, b){
		  					if(a.published.date < b.published.date) { return 1; }
		  					if(a.published.date > b.published.date) { return -1; }
		  					else{ return 0}
		  				})*/
		  			}

		  		}).finally( ()=>{
		  			/*self.$nextTick(function(){
		  				
		  				if( self.publishnewslettertable == null ){

		  					self.publishnewslettertable = $('#publishenewslettertable').DataTable({
		  						"data": self.publishedtable,
		  						"searching": false , 
		  						"bInfo": false, 
		  						"paging": false,
		  						"bLengthChange":false,
		  						"bAutoWidth": false,
		  						"aoColumns" : [
						            { sWidth: '50px' },
						            { sWidth: '50px' },
						            { sWidth: '50px' },
						            { sWidth: '50px' }
						        ],
		  						"responsive": true,
		  						"processing": true,
		  						"serverSide": false,
		  						"language": {
		  							"emptyTable": "There are no published reports available."
		  						},
		  						"columns": [
		  						{"title":"Title","data":function(data){return data.name},"orderable": false },
		  						{"title":"Period covered","orderable": false,"data": function( data ){ 
		  							return  self.timeFormat(data.periodstart) + " - " + self.timeFormat(data.periodend)
		  						}, className: "text-center"},
		  						{"title":"Published by","orderable": true, "data": function(data){
		  							return data.published.publishedby.firstname + " " + data.published.publishedby.lastname
		  						},  className: "text-center"},
		  						{"title":"Published on","orderable": true , "data": function(data){
		  							return self.dateFormat(data.published.date)
		  						}, className: "text-center" },
		  						{"title":"Views","orderable": true,"data": function(data){

		  							if( data.statistics ){
		  								if( data.statistics.viewcount){
		  									return data.statistics.viewcount
		  								}else{
		  									return "0"
		  								}

		  							}else{
		  								return "0"
		  							}

		  						}, className: "text-right"},
		  						{"title":"Users","orderable": true,"data": function(data){

		  							if( data.statistics ){
		  								if( data.statistics.viewcountunique){
		  									return data.statistics.viewcountunique
		  								}else{
		  									return "0"
		  								}

		  							}else{
		  								return "0"
		  							}

		  						}, className: "text-right"},

		  						],
		  						"order": [[ 3, "desc" ]],    
		  					});


		  					setTimeout( function(){
		  						 //self.publishnewslettertable.fnAdjustColumnSizing();
		  					},3000)

		  				}
		  			})*/



		  			/*$('#publishenewslettertable').on('click', 'tr', function () {

		  				var data = self.publishnewslettertable.row( this ).data();
		  				if( data ){
		  					//self.$func.addQuery({nid:data._id,page:'newsletter'},self.$route, self.$router)
		  					//self.opennewsletterforviewing(data._id,self.cockpit._id)
		  				}

		  			});
*/

		  		})




		  	},

		  	opennewsletterforcreating(){

		  		this.opencockpitid = this.cockpit._id
		  		this.creatingnewsletter=true
		  		this.viewmode=false

		  		this.$nextTick(function(){
		  			bus.$emit('onopennewsletterforcreating')
		  		})
		  		
		  	},

		  	opennewsletterforediting( newsletter, cockpitid, force){

		  		let self = this

		  		var nid = newsletter._id

		  		this.activenewsletter = newsletter

		  		if( force === true ){

		  		}else{

		  			if(  newsletter.lock.userid && ( newsletter.lock.userid != this.profile._id )){
		  				return false
	  				}	
		  		}  			

		  		this.opencockpitid = this.cockpit._id

  				var tourl = "/" + this.orgkey + "/projects/" + this.cockpitid + "/reportsedit?nid=" + nid + "&page=newsletter&openexisting=true&forceopen=" + force
					this.$router.push( tourl )


		  	},

		  	opennewsletterforviewing(newsletter){

		  		var nid =  newsletter.id
		  		const cockpitid = this.cockpit._id

		  		var tourl = "/" + this.orgkey + "/projects/" + cockpitid + "/reportsview?nid=" + nid + "&page=newsletter&openexisting=true"
				this.$router.push( tourl )

		  		//bus.$emit('onopennewsletterforviewing', nid, this.opencockpitid, this.countthisview() )
		  	},

		  	closingopenlocknewsletter(){
		  		this.openlocknewsletter = false
		  		this.$func.removeQuery(['page','nid'],this.$route,this.$router)
		  	}
		  }
		}
	</script>
